
import { defineComponent, onMounted } from "vue";
import SettleCreditNote from "@/components/finance/SettleCreditNote.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "finance-settle-credit-note",
  components: {
    SettleCreditNote
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Settle Credit Note", ["Credit Notes"]);
    });
  },
  created() {
    //Set page title
    document.title = "Settle Credit Note | " + process.env.VUE_APP_TITLE;
  }
});
